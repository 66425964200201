@import "../../styles/themes";
@import "../../styles/typography";

.responsive-list--root {
  position: relative;
}
.responsive-list--wrapper {
  display: flex;
  justify-content: flex-end;
  .responsive-list-menu-button {
    flex-shrink: 0;
  }
}
.responsive-list--dummy {
  width: 100%;
  position: absolute;
  visibility: hidden;
}
.responsive-list--menu-button-dialog {
  display: flex;
}
.responsive-list-menu-wrapper-flex {
  display: flex;
}
