@import "../../styles/states";

.expand-collapse {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: var(--primary-text-color);
  background-color: transparent;
  box-sizing: border-box;
  border-radius: var(--border-radius-small);
}

.expand-collapse__header {
  @include reset-button();

  padding: var(--spacing-medium);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-background-hover-color);
  }

  .expand-collapse__header-content {
    margin: 0;
  }

  .icon_component {
    color: var(--icon-color);
  }
}
.expand-collapse__content {
  padding: var(--spacing-medium);
}

.expand-collapse__section {
  width: 100%;
}

.animate-icon-open {
  transform: rotate(-180deg);
  transition: transform var(--motion-expressive-short) var(--motion-timing-transition);
}

.animate-icon-close {
  transform: rotate(-360deg);
  transition: transform var(--motion-expressive-short) var(--motion-timing-transition);
}

.animate-expand-collapse__content {
  animation: expandContentAnimation var(--motion-productive-medium) var(--motion-timing-enter);
}

@keyframes expandContentAnimation {
  0% {
    opacity: 0;
    transform: translateY(var(--dropdown-menu-fadinY, -75px));
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
