@import "../../../styles/themes";
@import "../../../styles/typography";
@import "../../../styles/states";
@import "../../../styles/global-css-settings";

.monday-style-menu-item {
  margin: unset;
  @include font-default;
  display: flex;
  flex-direction: row;
  padding: $spacing-xs $spacing-small;
  @include theme-prop(color, primary-text-color);
  cursor: pointer;
  user-select: none;
  align-items: center;
  border: 1px solid transparent;
  outline: none;

  .monday-style-menu-item__icon-wrapper {
    margin-right: $spacing-small;
  }

  .monday-style-menu-item__sub_menu_icon-wrapper {
    margin-left: $spacing-xs;
  }

  .monday-style-menu-item__sub_menu_icon-wrapper,
  .monday-style-menu-item__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .monday-style-menu-item__sub_menu_icon,
    .monday-style-menu-item__icon {
      @include theme-prop(color, icon-color);
    }
  }

  &.monday-style-menu-item--disabled {
    cursor: not-allowed;
    @include theme-prop(color, disabled-text-color);

    .monday-style-menu-item__sub_menu_icon,
    .monday-style-menu-item__icon {
      cursor: not-allowed;
      @include theme-prop(color, disabled-text-color);
    }
  }

  &:not(.monday-style-menu-item--disabled) {
    &.monday-style-menu-item--focused {
      @include theme-prop(background-color, primary-background-hover-color);
      &:not(:hover):not(.monday-style-menu-item-initial-selected) {
        @include focus-style-css();
      }
      border-radius: var(--border-radius-small);
    }

    &.monday-style-menu-item--selected {
      @include theme-prop(background-color, primary-selected-color);
      border-radius: var(--border-radius-small);

      .monday-style-menu-item__icon-wrapper {
        .monday-style-menu-item__icon {
          @include theme-prop(color, primary-color);
        }
      }
    }
  }

  .monday-style-menu-item__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }

  .monday-style-menu-item__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 0;
    @include font-caption();
  }
}
