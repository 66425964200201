@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/states";

.monday-style-clickable {
  @include focus-style;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  &--disable-text-selection {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
}
