@import "../../styles/states";
@import "../../styles/global-css-settings";

.monday-style-toggle {
  // Since it is not possible to change the design of the checkbox according to the storybook toggle requirements using css,
  // we hide the checkbox and draw a new one instead.
  // In order to allow accessibility, all operations will be performed on the hidden checkbox and be reflected
  // in the new toggle we drew.
  &_input {
    // When the hidden checkbox will be focused by keyboard navigation events, the toggle appearance will reflect it
    &:focus-visible,
    &.focus-visible {
      & ~ .monday-style-toggle_toggle {
        @include focus-style-css();
      }
    }
  }

  &_toggle {
    @include reset-button();
    transition: background-color var(--motion-productive-medium) var(--motion-timing-transition);
    margin: 0 $spacing-small;
    position: relative;
    height: 24px;
    width: 41px;
    cursor: pointer;
    border-radius: 100px !important;

    &::after {
      @include theme-prop(background-color, primary-background-color);
      content: " ";
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      top: calc(50% - 18px / 2);
      transition: left var(--motion-productive-medium) var(--motion-timing-transition);
    }

    &--selected {
      @include theme-prop(background-color, primary-color);
      &::after {
        left: 20px;
      }
    }

    &--not-selected {
      @include theme-prop(background-color, ui-border-color);
      &::after {
        left: 3px;
      }
    }
  }

  &_text {
    @include theme-prop(color, primary-text-color);
    font-style: normal;
    font-weight: normal;
    size: 14px;
    line-height: 22px;
  }

  &_wrapper {
    display: flex;
    align-items: center;
    &--disabled {
      & .monday-style-toggle_toggle {
        opacity: var(--disabled-component-opacity);
        cursor: not-allowed;
      }
      & .monday-style-toggle_text {
        color: var(--disabled-text-color);
      }
    }
  }
}
