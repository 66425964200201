@import "../../../styles/states";
@import "../../../styles/themes";
@import "../../../styles/typography";

.tab--wrapper {
  position: relative;
  display: inline-block;
  padding-left: 1px;
  padding-right: 1px;
  border-top: 1px solid transparent;
  border-bottom: 2px solid;
  @include theme-prop(border-bottom-color, primary-background-hover-color);
  @include theme-prop(color, primary-text-color);
  text-align: center;
  height: 100%;

  &:after {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    content: "";
    border-bottom: solid 2px;
    @include theme-prop(border-bottom-color, primary-color);
    transform: scaleX(0);
    z-index: 0;
  }

  .tab-inner {
    $margin-bottom: 1px;
    height: calc(100% - #{$margin-bottom});
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 4px 16px;
    font-weight: 400;
    font-size: 16px;
    user-select: none;
    margin-bottom: $margin-bottom;
    cursor: pointer;
    @include theme-prop(color, primary-text-color);

    &:focus {
      outline: none;
    }

    .tab-icon {
      @include theme-prop(color, icon-color);
      margin-right: 8px;

      &.right {
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }

  &.tab-focus-visible-inset {
    @include focus-style-css-inset(3px, 3px);
    position: relative;
  }

  &.active {
    &:after {
      transform: scaleX(1);
      transition: transform var(--motion-productive-medium) var(--motion-timing-enter);
    }
  }

  &.disabled {
    .tab-inner {
      @include theme-prop(color, disabled-text-color);
      cursor: not-allowed;
    }
  }

  &:not(.disabled) {
    .tab-inner {
      &:hover {
        border-radius: 4px;
        @include theme-prop(background-color, primary-background-hover-color);
      }
    }
  }
}

.tab--wrapper .tab-inner{
  font-family: var(--font-family);
}
