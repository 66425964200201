@import "../../styles/global-css-settings";
@import "../../styles/themes";
@import "../../styles/typography";

.monday-style-button-group-component {
  display: flex;
  flex-direction: column;

  &__sub-text-container {
    @include font-caption();
  }

  &__buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .monday-style-button {
      border: 1px solid;
      @include theme-prop(background-color, primary-background-color);
      @include theme-prop(border-color, ui-border-color);
      &.monday-style-button--color {
        &-primary {
          &-active {
            z-index: 20;
            &:hover {
              @include theme-prop(background-color, primary-selected-color);
            }
            @include theme-prop(background-color, primary-selected-color);
            @include theme-prop(border-color, primary-color);
          }
        }
      }
    }
  }
  &__option-text {
    &:not(.disabled) {
      @include theme-prop(color, primary-text-color);
      &.selected {
        z-index: 10;
        @include theme-prop(color, primary-text-color);
      }
    }
  }

  &--kind {
    &-tertiary {
      .monday-style-button {
        border-color: transparent;

        &:first-child {
          border-color: transparent;
        }
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  .disabled {
    cursor: not-allowed;
    background-color: var(--disabled-background-color);
    opacity: 0.5;
  }

  .button-disabled {
    background-color: var(--disabled-background-color);
    opacity: 0.5;
    cursor: not-allowed;
  }
}
