@import "../../../styles/global-css-settings";
@import "../../../styles/states";
@import "../../../styles/themes";

.monday-slider {
  &__base {
    display: flex;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
  }

  &__rail {
    cursor: pointer;
    display: inline-block;
    position: relative;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
  }

  &__track {
    position: absolute;
    border-radius: inherit;
    @include theme-prop(background-color, primary-selected-color);
    height: inherit;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  &__filled-track {
    position: absolute;
    border-radius: inherit;
    @include theme-prop(background-color, primary-color);
    height: inherit;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  &__thumb {
    position: absolute;
    border: 3px solid;
    border-radius: 50%;
    @include theme-prop(border-color, primary-color);
    @include theme-prop(background-color, primary-background-color);
    font-size: 14px;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transform var(--motion-productive-long) var(--motion-timing-enter);
    &--dragging {
      cursor: grabbing !important;
      transform: translate(-50%, -50%) scale(1.33, 1.33);
      transform-origin: center center;
      transition: transform var(--motion-productive-long) var(--motion-timing-enter);
      @include box-shadow-small();
    }
    &--focused {
      @include theme-prop(border-color, primary-hover-color);
      cursor: grab;
      @include box-shadow-small();
    }
  }

  &__thumb-label {
    bottom: calc(100% + 8px);
    left: 50%;
    max-width: 50px;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    transform: translate(-50%, 0);
    white-space: nowrap;
  }

  // thumb (for NOT-disabled)
  &:not(&--disabled) {
    .monday-slider {
      &__thumb {
        @include focus-style(50%);
        &:hover {
          @include theme-prop(border-color, primary-hover-color);
          cursor: grab;
          @include box-shadow-small();
        }
      }
    }
  }

  // modifications

  &__base {
    &--disabled {
      * {
        pointer-events: none;
      }
      cursor: not-allowed !important;
      opacity: var(--disabled-component-opacity);
    }

    &--small {
      padding: 0 8px;
      .monday-slider {
        &__rail {
          border-radius: 2px;
          height: 2px;
          padding: 8px 0;
        }
        &__thumb {
          height: 16px;
          width: 16px;
        }
      }
    }

    &--medium {
      padding: 0 12px;
      .monday-slider {
        &__rail {
          border-radius: 2px;
          height: 4px;
          padding: 12px 0;
        }
        &__thumb {
          height: 24px;
          width: 24px;
        }
      }
    }

    &--large {
      padding: 0 12px;
      .monday-slider {
        &__rail {
          border-radius: 8px;
          height: 8px;
          padding: 10px 0;
        }
        &__thumb {
          height: 24px;
          width: 24px;
        }
      }
    }

    &--positive {
      .monday-slider {
        &__track {
          @include theme-prop(background-color, positive-color-selected);
        }
        &__filled-track {
          @include theme-prop(background-color, positive-color);
        }
        &__thumb {
          @include theme-prop(border-color, positive-color);
        }
      }
      &:not(&--disabled) {
        .monday-slider {
          &__thumb {
            &:hover {
              @include theme-prop(border-color, positive-color-hover);
            }
          }
        }
      }
    }

    &--negative {
      .monday-slider {
        &__track {
          @include theme-prop(background-color, negative-color-selected);
        }
        &__filled-track {
          @include theme-prop(background-color, negative-color);
        }
        &__thumb {
          @include theme-prop(border-color, negative-color);
        }
      }
      &:not(&--disabled) {
        .monday-slider {
          &__thumb {
            &:hover {
              @include theme-prop(border-color, negative-color-hover);
            }
          }
        }
      }
    }
  }
}

.monday-slider__thumb{
  font-family: var(--font-family);
}
