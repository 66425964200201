@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";

.dialog-content-container {
  &:focus {
    outline: none;
  }

  &--small {
    padding: $spacing-small;
  }

  &--medium {
    padding: $spacing-small;
  }

  &--large {
    padding: $spacing-large;
  }

  &--popover {
    @include theme-prop(box-shadow, box-shadow-medium);
    border-radius: var(--border-radius-medium);
    @include theme-prop(background-color, secondary-background-color);
  }

  &--modal {
    @include theme-prop(box-shadow, box-shadow-large);
    border-radius: var(--border-radius-big);
    @include theme-prop(background-color, primary-background-color);
  }
}
