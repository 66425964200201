@import "../../../styles/themes";
@import "../../../styles/global-css-settings";

.monday-style-dialog-content-wrapper {
  outline: 0;

  &[data-popper-reference-hidden="true"] {
    visibility: hidden;
    pointer-events: none;
  }
}

.monday-style-animation-opacity-slide-appear {
  opacity: 0;

  &.top {
    transform: translateY($spacing-medium);
  }

  &.right {
    transform: translateX(-$spacing-medium);
  }

  &.bottom {
    transform: translateY(-$spacing-medium);
  }

  &.left {
    transform: translateX($spacing-medium);
  }
}

.monday-style-animation-opacity-slide-appear-active {
  transition: opacity 0.2s ease, transform 0.2s ease-out;
  opacity: 1;
  pointer-events: none;

  &.top,
  &.bottom {
    transform: translateY(0);
  }

  &.right,
  &.left {
    transform: translateX(0);
  }
}

.monday-style-animation-expand-appear,
.monday-style-animation-expand-exit {
  transition: transform 0.1s $expand-animation-timing;
  &.top,
  &.top-start,
  &.top-end {
    transform-origin: bottom center;
    transform: scale(0.8);
    &.edge-bottom {
      transform-origin: bottom left;
    }
    &.edge-top {
      transform-origin: bottom right;
    }
  }

  &.right,
  &.right-start,
  &.right-end {
    transform-origin: left;
    transform: scale(0.8);
    &.edge-bottom {
      transform-origin: top left;
    }
    &.edge-top {
      transform-origin: bottom left;
    }
  }

  &.bottom,
  &.bottom-start,
  &.bottom-end {
    transform-origin: top;
    transform: scale(0.8);
    &.edge-bottom {
      transform-origin: top left;
    }
    &.edge-top {
      transform-origin: top right;
    }
  }

  &.left,
  &.left-start,
  &.left-end {
    transform-origin: right;
    transform: scale(0.8);
    &.edge-bottom {
      transform-origin: top right;
    }
    &.edge-top {
      transform-origin: bottom right;
    }
  }
}

.monday-style-animation-expand-exit {
  transition: transform 0.1s $expand-animation-timing;
}

.monday-style-animation-expand-appear-active {
  transition: transform 0.1s $expand-animation-timing;
  pointer-events: none;

  &.top,
  &.top-start,
  &.top-end,
  &.bottom,
  &.bottom-start,
  &.bottom-end,
  &.right,
  &.right-start,
  &.right-end,
  &.left,
  &.left-start,
  &.left-end {
    transform: scale(1);
  }
}

.monday-style-dialog-content-component {
  &:focus {
    outline: none;
  }

  &.has-tooltip {
    padding: 6px;
  }
}
