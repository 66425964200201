@import "../../../styles/themes";
@import "../../../styles/typography";
@import "../../../styles/global-css-settings";

.monday-style-menu-title {
  @include font-default;

  .monday-style-menu-title__caption {
    @include theme-prop(color, icon-color);
    margin: $spacing-small 0;
  }

  .monday-style-menu-title__caption--center {
    display: flex;
    flex-basis: 100%;
    align-items: center;
  }

  .monday-style-menu-title__caption--bottom,
  .monday-style-menu-title__caption--top {
    padding-left: $spacing-small;
  }

  .monday-style-menu-title__caption--bottom {
    padding-bottom: $spacing-small;
  }

  .monday-style-menu-title__caption--center::before,
  .monday-style-menu-title__caption--center::after {
    content: "";
    flex-grow: 1;
    @include theme-prop(background-color, ui-border-color);
    height: 1px;
    font-size: 0;
    line-height: 0;
    margin: 0 $spacing-small;
  }
}

.monday-style-menu-title .monday-style-menu-title__caption--center::before,
  .monday-style-menu-title__caption--center::after{
  font-family: var(--font-family);
}
