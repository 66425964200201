@import "../../styles/global-css-settings";

.color-picker--wrapper{
  &.color-picker-dialog-content{
    z-index: 1;
    padding: $spacing-small-medium;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}