body {
  margin: 16px;
  font-family: "Roboto", sans-serif;
  background-color: var(--primary-background-color);
  color:var(--primary-text-color);
}

.centered {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: var(--spacing-medium);
}

table {
  border-collapse: collapse;
  width:100%;
  margin-bottom: var(--spacing-medium);
}

table td {
	padding: 15px;
}

table thead td {
	background-color: #54585d;
	color: #ffffff;
	font-weight: bold;
	font-size: 13px;
	border: 1px solid #54585d;
}

table thead td.danger {
  background-color: #c00;
}

table tbody td {
	color: #636363;
	border: 1px solid #dddfe1;
}

table tbody tr {
	background-color: #f9fafb;
}

table tbody tr:nth-child(odd) {
	background-color: #ffffff;
}

.placeholder {
  color: var(--placeholder-color);
}

.placeholder.is-selected {
  color:var(--primary-text-color) !important;
  font-weight: bold;
}

.dragMove {
  cursor: move !important;
}