@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";
@import "../../styles/keyframes";

.monday-style-counter {
  display: inline-flex;
  justify-content: center;
  border-radius: 30px;
  overflow: hidden;
  transform-origin: center;

  &--with-animation {
    animation: counter-pop-elastic var(--motion-expressive-short);
  }

  &--size {
    &-small {
      @include font-caption();
      min-width: 18px;
      line-height: 18px;
      padding: 0 $spacing-xs;
    }

    &-medium {
      @include font-caption();
      min-width: 18px;
      line-height: 18px;
      padding: 0 $spacing-xs;
    }

    &-large {
      @include font-input();
      line-height: 24px;
      min-width: 24px;
      padding: 0 $spacing-small;
    }
  }

  &--fade {
    /* New number enters */
    &-enter {
      opacity: 0;
      transform: translateY(15px);
    }

    &-enter-active {
      position: relative;
      opacity: 1;
      transition: transform var(--motion-productive-long) var(--motion-timing-enter), opacity var(--motion-productive-short);
      transform: translateY(0);
    }

    /* Old number exits */
    &-exit {
      position: relative;
      opacity: 1;
      transform: translateY(0);
    }

    &-exit-active {
      position: relative;
      opacity: 0;
      transform: translateY(-15px);
      transition: transform var(--motion-productive-long) var(--motion-timing-exit), opacity var(--motion-productive-short);
    }
  }

  &--kind {
    &-fill {
      &.monday-style-counter {
        &--color {
          &-primary {
            color: var(--text-color-on-primary);
            background-color: var(--primary-color);
          }

          &-dark {
            color: var(--text-color-on-inverted);
            background-color: var(--inverted-color-background);
          }

          &-negative {
            color: var(--text-color-on-primary);
            background-color: var(--negative-color);
          }

          &-light {
            color: var(--primary-text-color);
            background-color: var(--primary-background-hover-color);
          }
        }
      }
    }

    &-line {
      box-shadow: 0 0 0 1px currentColor inset;

      &.monday-style-counter {
        &--color {
          &-primary {
            @include theme-prop(color, primary-color);
          }

          &-dark {
            @include theme-prop(color, inverted-color-background);
          }

          &-negative {
            @include theme-prop(color, negative-color);
          }

          &-light {
            color: var(--primary-text-color);
            box-shadow: 0 0 0 1px var(--primary-background-hover-color) inset !important;
          }
        }
      }
    }
  }

  @include keyframe(counter-pop-elastic){
    @include pop-elastic();
  }
}
