@import "../../../../styles/themes";
@import "../../../../styles/typography";
@import "../../../../styles/states";

.combobox-category {
  @include theme-prop(color, placeholder-color);
  display: flex;
  align-items: center;
  padding-top: var(--spacing-small);
}
