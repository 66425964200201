@import "../../styles/typography";

$avatar-icon-large: 28px;
$avatar-icon-medium: 18px;
$avatar-icon-small: 12px;
$font-size-avatar-large: 18px;
$font-size-avatar-medium: 14px;
$font-size-avatar-small: 12px;

.monday-style-avatar-content {
  &_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &_text {
    font-family: var(--font-family);
    font-weight: var(--font-weight-normal);
    line-height: 21px;
    letter-spacing: -0.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &--large {
      font-size: $font-size-avatar-large;
    }

    &--medium {
      font-size: $font-size-avatar-medium;
      margin-top: 1px;
    }

    &--small {
      margin-top: 1px;
      font-size: $font-size-avatar-small;
    }
  }

  &_icon {
    &--large {
      height: $avatar-icon-large;
      width: $avatar-icon-large;
    }

    &--medium {
      height:  $avatar-icon-medium;
      width: $avatar-icon-medium;
    }

    &--small {
      height:  $avatar-icon-small;
      width: $avatar-icon-small;
    }
  }
}