@import "../../styles/themes";

.monday-slider {
  align-items: center;
  display: flex;
  max-width: 500px;
  min-width: 200px;
  width: 100%;

  &--value-shown {
    margin-top: 10px;
  }

  &__infix {
    font-size: 14px;
    flex: 0 0 auto;
    &--txt {
      white-space: nowrap;
    }
    .input-component__input {
      text-align: center;
    }
    .input-component .input-component__input-wrapper .input-component__input {
      padding: var(--spacing-small);
    }
    &--prefix {
      margin-right: var(--spacing-small);
    }
    &--postfix {
      margin-left: var(--spacing-small);
    }
  }
}

.monday-slider__infix{
  font-family: var(--font-family);
}
