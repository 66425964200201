@import "../../../styles/global-css-settings";
@import "../../../styles/themes";
@import "../../../styles/typography";

.linear-progress-bar--wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-family: var(--font-family);
  font-size: var(--font-size-general-label);
  @include theme-prop(color, secondary-text-color);

  &.linear-progress-bar--wrapper--full-width {
    width: 100%;
    .linear-progress-bar__container {
      border-radius: 0;
      .linear-progress-bar__content {
        border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;

        &.linear-progress-bar__content--completed {
          border-radius: 0;
        }
      }
    }
  }

  .linear-progress-bar__label {
    padding-left: var(--spacing-xs)
  }

  &__large {
    height: 8px;
    line-height: 8px;
  }

  &__medium {
    height: 6px;
    line-height: 6px;
  }

  &__small {
    height: 4px;
    line-height: 4px;
  }

  .linear-progress-bar__container {
    position: relative;
    width: 100%;
    @include theme-prop(background-color, primary-background-hover-color);
    border-radius: $border-radius-small;

    .linear-progress-bar__secondary,
    .linear-progress-bar {
      left: 0;
      height: 100%;
      position: absolute;
      border-radius: $border-radius-small;

      &--animate {
        transition: width var(--motion-productive-medium) var(--motion-timing-transition);
      }

      &--primary {
        @include theme-prop(background-color, primary-color);
      }

      &--secondary {
        @include theme-prop(background-color, dark-color);
      }

      &--positive {
        @include theme-prop(background-color, positive-color);
      }

      &--negative {
        @include theme-prop(background-color, negative-color);
      }
    }

    .linear-progress-bar__secondary {
      &--primary {
        background-color: var(--primary-selected-color);
      }

      &--secondary {
        background-color: var(--ui-border-color);
      }

      &--positive {
        background-color: var(--positive-color-selected);
      }

      &--negative {
        background-color: var(--negative-color-selected);
      }
    }
  }
}

.linear-progress-bar--wrapper{
  font-family: var(--font-family)
}
