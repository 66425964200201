@import "../../styles/typography";

.formatted-number-component {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  font-family: var(--font-family);
  width: 100%;

  &__prefix {
    white-space: pre;
  }

  &__suffix {
    white-space: pre;
  }
}
