@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";

.heading-component {
  border: 1px solid transparent;
  border-radius: $border-radius-small;
  min-width: 0;
  @include heading-padding();

  &.multi-line-ellipsis {
    @include line-clamp(var(--heading-clamp-lines));
  }

  // This is needed to support ellipsis in the middle of the word
  &.single-line-ellipsis {
    @include ellipsis();
  }

  &.suggest-edit-on-hover {
    &:hover {
      cursor: text;
      border: 1px solid;
      @include theme-prop(border-color, ui-border-color);
    }
  }
}

// Override tags styles
.element-type-h1 {
  letter-spacing: -0.2px;
  font-size: $h1-legacy-font-size;
  line-height: $h1-legacy-line-height;
  font-weight: var(--font-weight-bold);
  font-family: var(--title-font-family);
  -webkit-font-smoothing: var(--font-smoothing-webkit);
  -moz-osx-font-smoothing: var(--font-smoothing-moz);

  &.size-small,
  &.size-medium {
    font-size: var(--font-size-h2);
    line-height: var(--font-line-height-h2);
  }
}

.element-type-h2 {
  letter-spacing: -0.2px;
  @include font-title();
  &.size-small,
  &.size-medium {
    @include font-subtitle();
  }
}
.element-type-h3 {
  letter-spacing: -0.2px;
  @include font-title-secondary();
  &.size-small,
  &.size-medium {
    @include font-subtitle();
  }
}

.element-type-h4 {
  letter-spacing: -0.2px;
  @include font-subtitle();
}

.element-type-h5 {
  @include font-paragraph-bold();
}

.element-type-h6 {
  @include font-general-text();
}

.element-type-p {
  @include font-paragraph();
}

.element-type-small {
  @include font-caption();
}

.element-type-link {
  @include font-link();
}
