.breadcrumbItem--wrapper {
  display: inline-flex;
  overflow: hidden;
  list-style-type: none;
  cursor: default;
  &.clickable {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
  }
}
