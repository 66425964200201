.search_component_wrapper {
  input[type="search"] {
    -webkit-appearance: textfield;
  }
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  &:focus-within {
    .search-focus-element-first {
      animation: dash-forward 5s linear forwards;
    }

    .search-focus-element-second {
      animation: dash-backwards 5s linear forwards;
    }
  }
}

.search_component {
  &.search_component--round {
    border-radius: 50px !important;
  }
}
@keyframes dash-forward {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash-backwards {
  to {
    stroke-dashoffset: 2000;
  }
}
