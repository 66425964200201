@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";

.monday-style-radio-button-component {
  display: grid;
  grid-template-columns: 1.5em auto;
  grid-gap: 0.5em;
  cursor: pointer;

  &__radio-input-container {
    display: flex;
    align-items: center;
    justify-content: center;

    &__radio-input {
      opacity: 0;
      width: 0;
      height: 0;
      margin: 0;
    }

    &__radio-control {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1em;
      height: 1em;
      border: 0.1em solid;
      box-sizing: border-box;
      @include theme-prop(border-color, ui-border-color);
      border-radius: 50%;
      transition: border-width var(--motion-productive-medium) var(--motion-timing-enter);
    }

    input:checked + &__radio-control {
      @include theme-prop(border-color, primary-color);
      border-width: 0.3em;
      &--label-animation {
        animation-name: radio-checked-animation;
        animation-duration: var(--motion-productive-medium);
        animation-timing-function: var(--motion-timing-enter);
        animation-fill-mode: forwards;
      }
    }

    input:disabled + &__radio-control {
      border: 0.5em solid;
      @include theme-prop(border-color, disabled-background-color);
      border-radius: 50%;
    }

    input:checked:disabled + &__radio-control {
      border: 0.3em solid;
      @include theme-prop(border-color, disabled-background-color);
      @include theme-prop(background-color, secondary-text-color);
    }
  }

  &__radio-label {
    line-height: 21px;
    font-weight: 400;
    font-size: var(--font-size-general-label);
    @include theme-prop(color, primary-text-color);
  }

  &.disabled {
    cursor: not-allowed;
    .monday-style-radio-button-component__radio-label {
      color: var(--disabled-text-color);
    }
  }

  &__radio-input-container input:focus-visible + &__radio-input-container__radio-control {
    outline: none;
    @include theme-prop(border-color, primary-color);
    box-shadow: 0 0 0 3px hsla(209, 100%, 50%, 0.5);
    border-radius: 50%;
  }

  &:hover &__radio-input-container input:enabled:checked + &__radio-input-container__radio-control {
    @include theme-prop(border-color, primary-hover-color);
  }

  &:hover &__radio-input-container input:enabled:not(:checked) + &__radio-input-container__radio-control,
  &:focus-within &__radio-input-container input:enabled:not(:checked) + &__radio-input-container__radio-control {
    @include theme-prop(border-color, primary-text-color);
  }
}

@keyframes radio-checked-animation {
  0% {
    height: 1em;
    width: 1em;
  }
  50% {
    height: 0.8em;
    width: 0.8em;
  }
  100% {
    height: 1em;
    width: 1em;
  }
}
