@import "../../../../styles/global-css-settings";
@import "../../../../styles/typography";
@import "../../../../styles/themes";

.color-picker-content--wrapper {
  .clear-color-icon {
    margin-right: $spacing-small;
  }
  .clear-color-button {
    margin-top: $spacing-small;
  }
  .color-picker {
    padding: 0;
    outline: none;
    display: flex;
    flex-wrap: wrap;
  }  
}
