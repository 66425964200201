
@import "../../../../styles/themes";
@import "../../../../styles/typography";
@import "../../../../styles/global-css-settings";
@import "../../../../styles/states";

$icon-margin: 4px;

.combobox-option {
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  user-select: none;

  &.disabled {
    cursor: not-allowed;
    @include disabled;
  }

  &.selected {
    @include theme-prop(background-color, primary-selected-color);
  }

  &:hover:not(.disabled):not(.selected),
  &.active {
    @include theme-prop(color, primary-text-color);
    @include theme-prop(background-color, primary-background-hover-color);
    position: relative;
  }

  &.active-outline {
    @include focus-style-css-inset();
    background-color: var(--secondary-background-color);
  }

  .option-label {
    flex: 1 1 auto;

    @include ellipsis();
  }

  .option-icon {
    flex: 0 0 auto;
    &.left {
      margin-right: $icon-margin;
    }
  }
}
