@import "../../../styles/themes";
@import "../../../styles/typography";

.tab-panels--wrapper {
  .tab-panel {
    display: none;

    &.animation-direction-rtl {
      transform: translateX(8px);
    }

    &.animation-direction-ltr {
      transform: translateX(-8px);
    }

    &.active {
      display: block;
      animation: panel-slide-in var(--motion-productive-long) forwards var(--motion-timing-enter);
    }
  }

  @keyframes panel-slide-in {
    100% { transform: translateX(0%); }
  }
}
