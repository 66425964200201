@import "../../../styles/global-css-settings";
@import "../../../styles/themes";

.monday-style-alert-banner-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.monday-style-alert-banner-text-margin-left {
  margin-left: $spacing-small;
}
