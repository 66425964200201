@import "../../../styles/typography";

.container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-block-end: var(--spacing-large);

  .title {
    @include font-title();
    color: var(--primary-text-color);
    margin-block-start: var(--spacing-large);
    margin-block-end: 0;
    width: calc(100% - 32px);
  }

  .icon {
    vertical-align: middle;
    margin-right: var(--spacing-small);
  }

  .description {
    font: var(--font-subtext);
    color: var(--primary-text-color);
    margin-block-start: var(--spacing-small);
  }

  .closeButton {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
