@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";

.monday-style-divider {
  @include theme-prop(background-color, layout-border-color);

  &.monday-style-divider--without-margin  {
    margin: 0;
  }

  &--horizontal {
    height: 1px;
    width: 100%;
    margin: var(--spacing-small) 0;
  }

  &--vertical {
    height: 100%;
    width: 1px;
    margin: 0 var(--spacing-xs);
  }
}
