@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";
@import "../../styles/states";

.menu-button--wrapper {
  @include reset-button();
  border-radius: $border-radius-small;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform var(--motion-productive-short) var(--motion-timing-transition);
  @include theme-prop(color, primary-text-color);
  @include focus-style();

  &:focus,
  &:hover {
    @include theme-prop(background-color, primary-background-hover-color);
  }

  &--open {
    transform: scale(0.95);
    @include theme-prop(background-color, primary-selected-color);
    @include theme-prop(color, primary-color);
    &:focus {
      @include theme-prop(background-color, primary-selected-color);
    }
    &:hover {
      @include theme-prop(background-color, primary-selected-color);
    }
  }

  &--size-16 {
    width: 16px;
    height: 16px;
    @include font-input();
  }

  &--size-24 {
    width: 24px;
    height: 24px;
    @include font-paragraph();
  }

  &--size-32 {
    width: 32px;
    height: 32px;
    @include font-paragraph();
  }

  &--size-40 {
    width: 40px;
    height: 40px;
    @include font-subtitle();
  }

  &--size-48 {
    width: 48px;
    height: 48px;
    @include font-title();
  }

  &--reference-icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--disabled {
    @include theme-prop(border-color, disabled-background-color);
    @include theme-prop(color, disabled-text-color);
    cursor: not-allowed;
    &:hover{
      background-color: transparent;
    }

  }

  &--text {
    width: 100%;
  }

  &--inner-text {
    margin: 0 $spacing-small;
  }
}
