@import "../../../styles/global-css-settings";
@import "../../../styles/typography";
@import "../../../styles/themes";

.monday-style-button.monday-style-alert-banner-button {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.dark-app-theme {
  .monday-style-alert-banner-button-dark-background {
    .monday-style-alert-banner-button {
      background-color: var(--dark-background-color);
    }
  }
}

.monday-style-alert-banner-button-margin-left {
  margin-left: $spacing-small;
}
