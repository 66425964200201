$avatar-badge-size-large: 19px;
$avatar-badge-size-medium: 13px;
$avatar-badge-size-small: 10px;

.monday-style-avatar-badge {
  &--large{
    height: $avatar-badge-size-large;
    width: $avatar-badge-size-large;
  }

  &--medium {
    height: $avatar-badge-size-medium;
    width: $avatar-badge-size-medium;
  }

  &--small {
    height: $avatar-badge-size-small;
    width: $avatar-badge-size-small;
  }
}
