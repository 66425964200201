.avatarGroupContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  padding: var(--spacing-xs) var(--spacing-small) var(--spacing-xs) var(--spacing-medium);
  margin: 0;
}

.avatarContainer {
  margin-left: calc(-1 * var(--spacing-small));
}
