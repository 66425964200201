@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";
@import "../../styles/keyframes";

.monday-style-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius-small;

  @include font-input();
  padding: 0 $spacing-small;
  position: relative;

  &--with-leg {
    border-radius: $border-radius-small $border-radius-small $border-radius-small 0;
  }

  &__leg-wrapper {
    position: absolute;
    height: 8px;
    top: 100%;
    left: 0;
    display: inline-flex;
  }

  &--with-animation {
    animation: label-spin-in-emphasized var(--motion-expressive-short) var(--motion-timing-emphasize);
    transform-origin: bottom left;
  }

  &--kind {
    &-fill {
      &.monday-style-label {
        &--color {
          &-primary {
            @include theme-prop(color, text-color-on-primary);
            @include theme-prop(background-color, primary-color);

            svg {
              path {
                @include theme-prop(fill, primary-color);
              }
            }
          }

          &-dark {
            @include theme-prop(color, text-color-on-inverted);
            @include theme-prop(background-color, inverted-color-background);

            svg {
              path {
                @include theme-prop(fill, inverted-color-background);
              }
            }
          }

          &-negative {
            @include theme-prop(color, text-color-on-primary);
            @include theme-prop(background-color, negative-color);

            svg {
              path {
                @include theme-prop(fill, negative-color);
              }
            }
          }

          &-positive {
            @include theme-prop(color, text-color-on-primary);
            @include theme-prop(background-color, positive-color);

            svg {
              path {
                @include theme-prop(fill, positive-color);
              }
            }
          }
        }
      }
    }

    &-line {
      border: 1px solid currentColor;

      .monday-style-label__leg-wrapper {
        left: -1px;
        top: calc(100% + 1px);
      }

      &.monday-style-label {
        &--color {
          &-primary {
            @include theme-prop(color, primary-color);

            svg {
              path {
                @include theme-prop(fill, primary-color);
              }
            }
          }

          &-dark {
            @include theme-prop(color, inverted-color-background);

            svg {
              path {
                @include theme-prop(fill, inverted-color-background);
              }
            }
          }

          &-negative {
            @include theme-prop(color, negative-color);

            svg {
              path {
                @include theme-prop(fill, negative-color);
              }
            }
          }

          &-positive {
            @include theme-prop(color, positive-color);

            svg {
              path {
                @include theme-prop(fill, positive-color);
              }
            }
          }
        }
      }
    }
  }
}

@include keyframe(label-spin-in-emphasized) {
  @include spin-in-emphasized();
}
