@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";
@import "../../styles/states";

.editable-heading--wrapper {
  width: 100%;
  min-width: 0;
  border: 1px solid transparent;
  border-radius: $border-radius-small;
  @include focus-style();
  &:focus,
  &:active {
    outline: none;
  }
  .heading-component {
    margin: 0;
  }
  &.inset-focus {
    @include focus-style-inset();
  }
}
