@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";


$tooltip-z-index: 9999999999999999;

.monday-style-tooltip {
  position: relative;
  display: inline-block;
  border-radius: $border-radius-small;
  padding: $spacing-small $spacing-medium;
  @include theme-prop(box-shadow, box-shadow-medium);
  @include font-input();
  max-width: 50vw;
  word-break: break-word;

  &.padding-size-md {
    border-radius: $border-radius-medium;
    padding: $spacing-medium;
    @include font-input();
  }

  a.tooltip-white-link {
    color: #fff;
  }
}

.monday-style-tooltip-dark,
.monday-style-tooltip-arrow.monday-style-arrow-dark {
  @include theme-prop(background-color, inverted-color-background);
  @include theme-prop(color, text-color-on-inverted);
}

.monday-style-tooltip-white,
.monday-style-tooltip-arrow.monday-style-arrow-white {
  background-color: $white-theme-bg-color;
  color: $white-theme-font-color;
  @include theme-prop(box-shadow, box-shadow-medium);
}

.monday-style-tooltip-primary,
.monday-style-tooltip-arrow.monday-style-arrow-primary {
  @include theme-prop(background-color, primary-color);
  @include theme-prop(color, text-color-on-primary);
}

.monday-style-tooltip-success,
.monday-style-tooltip-arrow.monday-style-arrow-success {
  @include theme-prop(background-color, positive-color);
  @include theme-prop(color, text-color-on-primary);
}

.monday-style-tooltip-surface,
.monday-style-tooltip-arrow.monday-style-arrow-surface {
  background-color: $surface-theme-bg-color;
  color: $surface-theme-font-color;
}

.monday-style-tooltip-private,
.monday-style-tooltip-arrow.monday-style-arrow-private {
  @include theme-prop(background-color, private-color);
  @include theme-prop(color, text-color-on-primary);
}

.monday-style-tooltip-share,
.monday-style-tooltip-arrow.monday-style-arrow-share {
  @include theme-prop(background-color, shareable-color);
  @include theme-prop(color, text-color-on-primary);
}

.monday-style-tooltip-error,
.monday-style-tooltip-arrow.monday-style-arrow-error {
  @include theme-prop(background-color, negative-color);
  @include theme-prop(color, text-color-on-primary);
}

.monday-style-dialog-content-component {
  &:focus {
    outline: none;
  }
}
