@import "../../../../styles/themes";
@import "../../../../styles/states";

.breadcrumb-content {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  @include theme-prop(color, secondary-text-color);
  padding: 0px 2px;
  overflow: hidden;
  text-decoration: none;

  .breadcrumb-text {
    color: inherit;
    margin: 0 4px;
    line-height: 22px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .breadcrumb-icon {
    height: 14px;
    width: 14px;
    flex-shrink: 0;
  }

  &:focus {
    outline: none;
  }

  &.clickable:hover,
  &:focus-within {
    @include theme-prop(background-color, primary-background-hover-color);
    border-radius: 4px;
  }

  &.current {
    @include theme-prop(color, primary-text-color);
    font-weight: 500;
  }

  &.disabled {
    @include disabled;
    pointer-events: none;
  }
}

.breadcrumb-content{
  font-family: var(--font-family);
}
