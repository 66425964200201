@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";


.banner {
  width: content-box;

  .banner--content {
    display: grid;
    position: relative;
    padding: $spacing-large;
    border-radius: $border-radius-medium;
    grid-gap: $spacing-small;
    justify-content: start;
    column-gap: 24px;
    align-content: center;
    background-color: var(--primary-background-hover-color);
    @include theme-prop(color, primary-text-color);

    .banner--close {
      position: absolute;
      top: 0;
      right: 0;
    }

    &.close-button-spacing {
      padding-right: $spacing-xl;
    }

    &.image-position__left,
    &.image-position__right {
      grid-auto-rows: auto;
      grid-auto-columns: auto;
    }

    &.image-position__left {
      grid-template-areas:
        "image  title    title   "
        "image  subtitle subtitle";
    }

    &.image-position__right {
      grid-template-areas:
        "title    title     image"
        "subtitle subtitle  image";
    }

    &.image-position__bottom,
    &.image-position__top {
      grid-auto-rows: auto;
      grid-template-columns: repeat(1, 1fr);
    }

    &.image-position__top {
      grid-template-areas:
        "image"
        "title"
        "subtitle";
    }
    &.image-position__bottom {
      grid-template-areas:
        "title"
        "subtitle"
        "image";
    }

    .banner--title {
      align-self: end;
      margin: 0;
      grid-area: title;
      height: 32px;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.2px;
      max-width: 782px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .banner--subtitle {
      @include font-paragraph();
      margin: 0;
      grid-area: subtitle;
      @include line-clamp(3);
    }

    .banner--image {
      align-self: center;
      justify-self: center;
      grid-area: image;
      width: 100px;
      height: 100px;
      border-radius: $border-radius-medium;
      object-fit: contain;
    }
  }

  &.rtl {
    .banner--content {
      text-align: right;

      .banner--close {
        position: absolute;
        top: 0;
        left: 0;
        right: auto;
      }

      &.close-button-spacing {
        padding-left: $spacing-xl;
      }

      &.image-position__left {
        grid-template-areas:
          "image  title    title"
          "image  subtitle subtitle";
      }

      &.image-position__right {
        grid-template-areas:
          "title    title     image"
          "subtitle subtitle  image";
      }
    }
  }
}


.banner .banner--content .banner--title{
  font-family: var(--title-font-family);
}
