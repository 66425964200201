@import "../../styles/themes";

.container {
  display: flex;
  flex-direction: row;
  &.justify {
    &Start {
      justify-content: flex-start;
    }

    &End {
      justify-content: flex-end;
    }

    &Center {
      justify-content: center;
    }

    &SpaceBetween {
      justify-content: space-between;
    }

    &SpaceAround {
      justify-content: space-around;
    }
  }
  &.align {
    &Start {
      align-items: flex-start;
    }

    &End {
      align-items: flex-end;
    }

    &Center {
      align-items: center;
    }

    &Stretch {
      align-items: stretch;
    }
  }
  &.direction {
    &Column {
      flex-direction: column;
    }
  }
  &.wrap {
    flex-wrap: wrap;
  }
}
