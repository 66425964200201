@import "../../../../styles/themes";
@import "../../../../styles/typography";
@import "../../../../styles/keyframes";

.monday-style-step-indicator-component {
  @include theme-prop(color, text-color-on-primary);
  font-size: var(--font-size-general-label);
  display: flex;
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;

  &__text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 16px;

    &__title {
      width: 100%;
      margin-bottom: var(--spacing-xs);

      .visually-hidden {
        display: none;
      }

      &__text {
        font: var(--font-h5);
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        @include theme-prop(color, primary-text-color);
        margin-bottom: 2px;
      }
    }

    &__subtitle__text {
      width: 100%;
      font-size: var(--font-size-general-label);
      font-weight: var(--font-weight-normal);
      color: var(--primary-text-color);
    }
  }

  &--text-placement-vertical {
    margin: 8px;
    flex-direction: column;
    flex: 1 1 0px;

    .monday-style-step-indicator-component {
      &__text-container {
        margin-left: 0;
        margin-top: var(--spacing-medium);
      }

      &__number-divider-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .monday-style-divider {
          @include theme-prop(background-color, ui-border-color);
          margin: 16px 0 16px 16px;
          height: 1px;
          flex-grow: 1;
        }
      }
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &--with-animation {
    animation: step-indicatior-circle-pop-elastic var(--motion-expressive-short);
  }

  &--swap {
    /* New state enters */
    &-enter {
      opacity: 0;
      transform: translateY(15px);
    }

    &-enter-active {
      position: relative;
      opacity: 1;
      transition: transform var(--motion-productive-long) var(--motion-timing-enter),
        opacity var(--motion-productive-short);
      transform: translateY(0);
    }

    /* Old state leaves */
    &-exit {
      position: relative;
      opacity: 1;
      transform: translateY(0);
    }

    &-exit-active {
      position: relative;
      opacity: 0;
      transform: translateY(-15px);
      transition: transform var(--motion-productive-long) var(--motion-timing-enter),
        opacity var(--motion-productive-short);
    }
  }

  &__number-container {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;

    &__text {
      margin: auto;
      display: flex;
      align-items: center;

      &__check-icon {
        width: 20px;
        height: 20px;
        margin-top: 2px;
      }
    }
  }

  &--type {
    &-primary {
      &__number-container {
        @include theme-prop(background-color, primary-color);

        &:hover {
          @include theme-prop(background-color, primary-hover-color);
        }
      }
    }

    &-danger {
      &__number-container {
        @include theme-prop(background-color, negative-color);

        &:hover {
          @include theme-prop(background-color, negative-color-hover);
        }
      }
    }

    &-success {
      &__number-container {
        @include theme-prop(background-color, positive-color);

        &:hover {
          @include theme-prop(background-color, positive-color-hover);
        }
      }
    }

    &-dark {
      &__number-container {
        @include theme-prop(background-color, inverted-color-background);
        @include theme-prop(color, text-color-on-inverted);

        &:hover {
          @include theme-prop(background-color, secondary-text-color);
        }
      }
    }
  }

  &--status {
    &-active {
      &__number-container {
        box-shadow: 0 0 0 4px var(--primary-background-color), 0 0 0 6px var(--primary-color);

        &.monday-style-step-indicator-component {
          &--type {
            &-danger {
              &__number-container {
                box-shadow: 0 0 0 4px var(--primary-background-color), 0 0 0 6px var(--negative-color);
              }
            }

            &-success {
              &__number-container {
                box-shadow: 0 0 0 4px var(--primary-background-color), 0 0 0 6px var(--positive-color);
              }
            }

            &-dark {
              &__number-container {
                box-shadow: 0 0 0 4px var(--primary-background-color), 0 0 0 6px var(--primary-text-color);
              }
            }
          }
        }
      }
    }

    &-pending {
      &__number-container {
        @include theme-prop(background-color, primary-background-color);
        @include theme-prop(color, primary-text-color);
        @include theme-prop(border-color, ui-border-color, true);
        border: 1px solid;

        &:hover {
          @include theme-prop(background-color, primary-color);
          @include theme-prop(color, text-color-on-primary);
          border: none;
        }

        &.monday-style-step-indicator-component {
          &--type {
            &-danger {
              &__number-container {
                &:hover {
                  @include theme-prop(background-color, negative-color);
                }
              }
            }

            &-success {
              &__number-container {
                &:hover {
                  @include theme-prop(background-color, positive-color);
                }
              }
            }

            &-dark {
              &__number-container {
                &:hover {
                  @include theme-prop(background-color, primary-text-color);
                  @include theme-prop(color, text-color-on-inverted);
                }
              }
            }
          }
        }
      }
    }
  }
}

@include keyframe(step-indicatior-circle-pop-elastic) {
  @include pop-elastic();
}

.monday-style-step-indicator-component {
  font-family: var(--font-family);
}

.monday-style-step-indicator-component__text-container__subtitle__text {
  font-family: var(--font-family);
}
