@import "../../../styles/themes";
@import "../../../styles/typography";

.tabs--wrapper {
  padding-bottom: 20px;

  .tabs-list {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    font-size: 0;
    position: relative;
    height: 40px;
    outline: none;
    font-family: var(--font-family);

    &.sm {
      height: 32px;
    }

    &.lg {
      height: 48px;
    }
  }

  &.stretched {
    .tabs-list {
      width: 100%;

      &_tab--wrapper {
        width: 100%;

        &_tab-inner {
          width: 100%;
        }
      }
    }
  }

  &.disabled {
    opacity: 0;
    height: 0;
    padding-bottom: 5px;
    pointer-events: none;
    .tabs-list {
      .tab--wrapper {
        .tab-inner:hover {
          cursor: default;
        }
      }
    }
  }
}
