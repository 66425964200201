@import "../../../../styles/states";
@import "../../../../styles/global-css-settings";

.monday-style-color-item-wrapper {
  --border-radius-multiplier: 1;

  &.circle {
    --border-radius-multiplier: 99999;
  }
  /* stylelint-disable scss/operator-no-unspaced */
  @mixin absolute-border($border-width, $border-color-var-name, $inset: -$border-width, $border-radius: 7px) {
    content: " ";
    position: absolute;
    border-radius: calc($border-radius * var(--border-radius-multiplier, 1));
    inset: $inset;
    border: $border-width solid;
    @include theme-prop(border-color, $border-color-var-name);
    pointer-events: none;
  }

  margin: 4px;
  display: flex;
  list-style-type: none;
  position: relative;

  .feedback-indicator {
    position: absolute;
    inset: 0;
    pointer-events: none;
  }

  &:focus,
  &:focus-visible,
  &.active {
    outline: none;

    .feedback-indicator {
      &:before {
        @include absolute-border(4px, primary-selected-color);
      }
      &:after {
        @include absolute-border(1px, primary-color);
      }
    }
  }

  &:hover {
    &:not(.active):not(.selected-color) {
      // active elements have no "hover feedback"
      .feedback-indicator {
        @include absolute-border(4px, primary-background-hover-color, -4px, 8px);
      }
    }
  }

  .color-item {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    border-radius: calc(4px * var(--border-radius-multiplier, 1));
    cursor: pointer;

    .color-indicator-wrapper {
      margin-top: 3px;
    }

    &.color-item-size-small {
      width: 24px;
      height: 24px;
    }

    &.color-item-size-medium {
      width: 32px;
      height: 32px;
    }

    &.color-item-size-large {
      width: 40px;
      height: 40px;
    }
  }

  &.selected-color {
    position: relative;
    &:before {
      @include absolute-border(2px, primary-color, -4px);
    }

    .feedback-indicator {
      &:before {
        // hide the outer border from the "active" feedback indicator
        display: none;
      }
    }
  }

  .color-item-text-mode:hover {
    background-color: var(--primary-background-hover-color) !important;
  }

  .color-icon-white {
    color: var(--color-snow_white);
  }
}
