@import "../../styles/global-css-settings";
@import "../../styles/typography";
@import "SkeletonVariables";

@mixin shine-animation() {
  overflow: hidden;
  &::before {
    display: inline-block;
    content:"";
    opacity: 0.1;
    background-image: linear-gradient(90deg, transparent 0%, var(--placeholder-color) 30%, var(--placeholder-color) 70%, transparent 100%);
    width: 300%;
    height: 300%;
    margin-bottom: 20px;
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shine;
    animation-timing-function: var(--motion-timing-enter);
  }

  @keyframes shine {
    0% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}

@mixin skeleton($radius: $border-radius-small) {
  @include theme-prop(background, disabled-background-color); // should be replaced with all grey color
  border-radius: $radius;
  @include shine-animation;

}

.monday-style-skeleton {
  display: flex;

  &_rectangle {
    height: $rectangle-default-size;
    @include skeleton;
  }

  &_circle {
    height: $circle-default-size;
    @include skeleton($circle-radius);
  }

  &_text {
    @include skeleton;
    &--h1 {
      height: $h1-empty-state-height
    }
    &--h2 {
      height: $h2-empty-state-height;
    }
    &--h3 {
      height: $h3-empty-state-height;
    }
    &--h4 {
      height: $h4-empty-state-height;
    }
    &--h5 {
      height: $h5-empty-state-height;
    }
    &--h6 {
      height: $h6-empty-state-height;
    }
    &--small, &--custom {
      height: $small-empty-state-height;
    }
  }
}
