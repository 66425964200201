.hideBorder {
  border: none;
}
.showBorder {
  border: 1px solid var(--ui-border-color);
  :global(.expand-collapse__header--open) {
    border-bottom: 1px solid;
    border-color: var(--ui-border-color);
  }
}

.hideBorderBottom {
  border-bottom: none;
}
