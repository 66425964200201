@import "../../styles/themes";

.breadcrumbs-bar--wrapper ol{
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;

    .sparatorIcon{
        @include theme-prop(color, secondary-text-color);
        flex-shrink: 0;
    }
}
