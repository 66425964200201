@import "../../../styles/themes";
@import "../../../styles/typography";

.monday-style-menu {
  margin: unset;
  padding: unset;
  &:focus {
    outline: none;
  }

  &--small {
    width: 200px;
  }

  &--medium {
    width: 220px;
  }

  &--large {
    width: 240px;
  }
}
