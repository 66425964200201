@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";

.editable-input--wrapper {
  @include theme-prop(background-color, primary-background-color);
  display: inline-block;
  width: 100%;
  min-width: 0;
  max-height: 100%;
  margin: auto 0;
  @include heading-padding();
  outline: none;
  border: 1px solid;
  border-radius: $border-radius-small;
  @include theme-prop(border-color, primary-color);
  vertical-align: top;
  text-align: left;
  &.no-resize {
    resize: none;
  }
}
