@import "../../../styles/themes";
@import "../../../styles/typography";
@import "../../../styles/global-css-settings";

.monday-style-menu-item-button {
  margin: unset;
  @include font-default;
  display: flex;
  flex-direction: row;
  padding: $spacing-xs 0;
  align-items: center;

  .monday-style-button {
    width: 100%;

    .menu-item-button-content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
