@import "../../styles/themes";

.tipseenImage {
  object-fit: cover;
  border: solid 2px;
  @include theme-prop(border-color, primary-color);
  width: 100%;
  border-radius: var(--border-radius-medium);
  position: relative;
  z-index: 1;
}